import { DiscoverProjects } from "./Components/DiscoverProjects";
import { Footer } from "./Components/Footer";
import { HeaderPublic } from "./Components/HeaderPublic";
import { WidgetHome } from "./Components/WidgetHome";

export const Index = () => {
  return (
    <div className="AppRootContent PublicApp">
      <HeaderPublic />
      <WidgetHome />
      <DiscoverProjects />
      <Footer />
    </div>
  );
};
