import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { Index } from "./Public/Index";
import { Project } from "./Public/Components/Project";

const AppPublic = () => {
  return (
    <Router>
      <div className="App LightTheme">
        <div className="Authenticated">
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/project/:projectId" element={<Project />} />
          </Routes>
        </div>
        {/* <div className="NotAuthenticated">LOGIN</div> */}
      </div>
    </Router>
  );
};

export default AppPublic;
