/* eslint-disable jsx-a11y/anchor-is-valid */

import { useNavigate } from "react-router-dom";

/* eslint-disable jsx-a11y/alt-text */
export const HeaderProject = () => {
  const navigate = useNavigate();

  return (
    <header className="AppHeader">
      <div className="boxed">
        <div
          className="logoPublic cp"
          onClick={() => {
            navigate("/");
          }}
        ></div>

        <div className="rightMenu">
          {/* <div className="socialLinks">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noreferrer"
              className="socialLink"
            >
              <img
                width={30}
                height={30}
                src="https://img.icons8.com/material-outlined/48/facebook-new.png"
              />
            </a>

            <a
              href="https://instagram.com"
              target="_blank"
              rel="noreferrer"
              className="socialLink"
            >
              <img
                width={30}
                height={30}
                src="https://img.icons8.com/material-outlined/48/instagram-new--v1.png"
              />
            </a>

            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer"
              className="socialLink"
            >
              <img
                width={26}
                height={26}
                src="https://img.icons8.com/material-outlined/48/twitterx--v2.png"
              />
            </a>
          </div> */}

          <div className="phoneCall">
            <a
              onClick={() => {
                navigate("/");
              }}
            >
              Ver Mas Proyectos
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
