/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useLocation, useNavigate } from "react-router-dom";
import { WhatsappIcon } from "../../Utils/Icons";
import { projects } from "../../Utils/Utils";

export const DiscoverProjects = () => {
  const navigate = useNavigate();

  return (
    <div className="DiscoverProjects">
      <div className="boxed">
        <div className="headerDP">
          <h1>Explora nuestros proyectos</h1>
          <p>
            *Los valores son aproximados y dependen de la propiedad y plan de
            pagos que elijas.
          </p>
          <br />
          <div className="NavBar">
            <div className="option active">TODOS</div>
            <div className="option">RECIDENCIALES</div>
            <div className="option">DEPARTAMENTOS</div>
          </div>
        </div>

        <div className="listProjects">
          {projects.map((project, index) => (
            <div className="projectCard" key={index}>
              <div
                className="imageProject"
                onClick={() => {
                  navigate("/project/" + project.id);
                }}
              >
                <div className="imageMark">
                  <img src={"assets/images/" + project.image} alt="" />
                  <div className="messageProject">{project.message}</div>
                </div>
              </div>
              <div className="titleProject">{project.title}</div>
              <div className="subtitleProject">{project.subtitle}</div>
              <div className="fromProject">
                Desde <span className="bold">${project.from}</span> MXN
              </div>

              <div className="monthProject">
                18 Mensualidades de *{" "}
                <span className="bold">${project.month}</span> MXN
              </div>

              <div className="gridPercentages">
                <div className="grid1">
                  <div className="title">Retorno Total</div>
                  <div className="percentages">17.9% - 23.8%</div>
                </div>

                <div className="grid2">
                  <div className="title">Renta Anualizado</div>
                  <div className="percentages">12.1% - 25.8%</div>
                </div>
              </div>

              <div className="gridButtonsAction">
                <a className="btnAction invest"> Invertir Ahora</a>
                <a
                  className="btnAction moreInfo"
                  onClick={() => {
                    navigate("/project/" + project.id);
                  }}
                >
                  Mas Información
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="more">Ver Mas Proyectos</div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};
