export const docs = [
  {
    title: "UBICACIÓN",
    desc: "LA RIOJA UBICACIÓN",
    doc: "UBICACION.pdf",
  },
  {
    title: "PLANO",
    desc: "PLANO DE LOTIFICACION LA RIOJA",
    doc: "PLANO.pdf",
  },
  {
    title: "PLANO",
    desc: "PLANO DE FUSION",
    doc: "PFUSION.pdf",
  },
];

export const projects = [
  {
    id: 0,
    image: "r1.png",
    title: "La Rioja Los Mochis",
    subtitle: "Sinaloa, México",
    from: "2,901,181",
    month: "13,838",
    message: "Ubicación estratégica y alta plusvalía ",
    docs,
  },
  {
    id: 1,
    image: "card1.png",
    title: "Privada Los Mochis",
    subtitle: "Sinaloa, México",
    from: "2,901,181",
    month: "13,838",
    message: "Precio 11% por debajo de la zona",
    docs,
  },
  {
    id: 2,
    image: "card2.png",
    title: "Edificio Guadalajara",
    subtitle: "Jalisco, México",
    from: "2,901,181",
    month: "13,838",
    message: "Ideal para rentas cortas",
    docs,
  },
  {
    id: 3,
    image: "card3.png",
    title: "Privada Monterrey",
    subtitle: "Nuevo León, México",
    from: "2,901,181",
    month: "13,838",
    message: "Un plan de pagos nunca antes visto",
    docs,
  },
  {
    id: 0,
    image: "r1.png",
    title: "La Rioja Los Mochis",
    subtitle: "Sinaloa, México",
    from: "2,901,181",
    month: "13,838",
    message: "Ubicación estratégica y alta plusvalía ",
    docs,
  },
  {
    id: 1,
    image: "card1.png",
    title: "Privada Los Mochis",
    subtitle: "Sinaloa, México",
    from: "2,901,181",
    month: "13,838",
    message: "Precio 11% por debajo de la zona",
    docs,
  },
  {
    id: 2,
    image: "card2.png",
    title: "Edificio Guadalajara",
    subtitle: "Jalisco, México",
    from: "2,901,181",
    month: "13,838",
    message: "Ideal para rentas cortas",
    docs,
  },
  {
    id: 3,
    image: "card3.png",
    title: "Privada Monterrey",
    subtitle: "Nuevo León, México",
    from: "2,901,181",
    month: "13,838",
    message: "Un plan de pagos nunca antes visto",
    docs,
  },
  {
    id: 0,
    image: "r1.png",
    title: "La Rioja Los Mochis",
    subtitle: "Sinaloa, México",
    from: "2,901,181",
    month: "13,838",
    message: "Ubicación estratégica y alta plusvalía ",
    docs,
  },
  {
    id: 1,
    image: "card1.png",
    title: "Privada Los Mochis",
    subtitle: "Sinaloa, México",
    from: "2,901,181",
    month: "13,838",
    message: "Precio 11% por debajo de la zona",
    docs,
  },
  {
    id: 2,
    image: "card2.png",
    title: "Edificio Guadalajara",
    subtitle: "Jalisco, México",
    from: "2,901,181",
    month: "13,838",
    message: "Ideal para rentas cortas",
    docs,
  },
  {
    id: 3,
    image: "card3.png",
    title: "Privada Monterrey",
    subtitle: "Nuevo León, México",
    from: "2,901,181",
    month: "13,838",
    message: "Un plan de pagos nunca antes visto",
    docs,
  },
  {
    id: 0,
    image: "r1.png",
    title: "La Rioja Los Mochis",
    subtitle: "Sinaloa, México",
    from: "2,901,181",
    month: "13,838",
    message: "Ubicación estratégica y alta plusvalía ",
    docs,
  },
  {
    id: 1,
    image: "card1.png",
    title: "Privada Los Mochis",
    subtitle: "Sinaloa, México",
    from: "2,901,181",
    month: "13,838",
    message: "Precio 11% por debajo de la zona",
    docs,
  },
  {
    id: 2,
    image: "card2.png",
    title: "Edificio Guadalajara",
    subtitle: "Jalisco, México",
    from: "2,901,181",
    month: "13,838",
    message: "Ideal para rentas cortas",
    docs,
  },
  {
    id: 3,
    image: "card3.png",
    title: "Privada Monterrey",
    subtitle: "Nuevo León, México",
    from: "2,901,181",
    month: "13,838",
    message: "Un plan de pagos nunca antes visto",
    docs,
  },
];

export const StyleMap = [
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d6e2e6",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#cfd4d5",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7492a8",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels.text.fill",
    stylers: [
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dde2e3",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#cfd4d5",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dde2e3",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7492a8",
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dde2e3",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#588ca4",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#a9de83",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#bae6a1",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c6e8b3",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#bae6a1",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#41626b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        saturation: -45,
      },
      {
        lightness: 10,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c1d1d6",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#a6b5bb",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#9fb6bd",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [
      {
        saturation: -70,
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#b4cbd4",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#588ca4",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#008cb5",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit.station.airport",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: -5,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#a6cbe3",
      },
    ],
  },
];
