/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
export const WidgetHome = () => {
  return (
    <div className="WidgetHome">
      <div className="ContentWidgetHome">
        <div className="title">
          Comienza tu camino en las inversiones con Circulo Inmobiliario
        </div>
        <div className="subtitle">Comunícate ahora para comenzar</div>
        <br />
        <div className="members">
          <div
            className="mem m1"
            onClick={() => {
              window.open(
                "https://wa.me/+5216681466678?text=Hola, Me interesa la membresia Oro ",
                "_blank"
              );
            }}
          >
            Tarjeta Oro
          </div>
          <div
            className="mem m2"
            onClick={() => {
              window.open(
                "https://wa.me/+5216681466678?text=Hola, Me interesa la membresia Platinum ",
                "_blank"
              );
            }}
          >
            Tarjeta Platinum <br />
            Descuento 1%
          </div>
          <div
            className="mem m3"
            onClick={() => {
              window.open(
                "https://wa.me/+5216681466678?text=Hola, Me interesa la membresia Diamante ",
                "_blank"
              );
            }}
          >
            Tarjeta Diamante <br />
            Descuento 2%
          </div>
          {/* <button className="actionWidgetHome">Ver Proyectos</button> */}
        </div>
      </div>
    </div>
  );
};
