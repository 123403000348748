/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DocumentAttachmentIcon, WhatsappIcon } from "../../Utils/Icons";
import { HeaderProject } from "./HeaderProject";
import { StyleMap, projects } from "../../Utils/Utils";
import { useEffect, useState } from "react";

export const Project = () => {
  (window as any).scrollTo(0, 0);
  const { projectId } = useParams();
  const project = projects.find((p: any) => p.id.toString() === projectId);
  console.log("project", project);
  const [showed, setShowed] = useState(false);

  const initMap = () => {
    var centerLatLng = { lat: 24.7540294, lng: -103.6600606 };

    // @ts-ignore
    var map = new google.maps.Map(document.getElementById("MapProject"), {
      center: centerLatLng,
      mapTypeControl: false,
      zoomControl: false,
      // fullscreenControl: false,
      streetViewControl: false,
      zoom: 5,
    });

    // @ts-ignore
    var style = new google.maps.StyledMapType(
      StyleMap, // Tu archivo JSON con el estilo del mapa
      { name: "Custom Style" }
    );

    // @ts-ignore
    map.mapTypes.set("custom_style", style);
    // @ts-ignore
    map.setMapTypeId("custom_style");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowed(true);

      initMap();
    }, 10);

    return () => clearTimeout(timeout);
  }, []);
  const navigate = useNavigate();

  return (
    <div className="AppRootContent PublicApp Project">
      <HeaderProject />

      <div className={`PageAnim ${showed ? "Showed" : ""}`}>
        <div
          className="WidgetHome"
          style={{
            background:
              "url(assets/images/" + project?.image + ") center center",
            backgroundSize: "cover",
          }}
        >
          <div className="ProjectGradient">
            <div className="boxed">
              <div className="small">Preventa</div>
              <div className="title">{project?.message}</div>
              <div className="titleProject">{project?.title}</div>
              <div className="subtitleProject">{project?.subtitle}</div>
              <div className="gallery">Galería en construcción</div>
            </div>
          </div>
        </div>

        <div className="boxed">
          <div className="options">
            <div className="fromPrice">
              <div className="aFP">Depas desde:</div>
              <div className="bFp">$2.9 millones MXN</div>
              <div className="cFP">*Valores estimados con el depa de 37m²</div>
            </div>

            <div className="monthsFrom">
              <div className="aMF">21 mensualidades desde:</div>
              <div className="bMF">$14 mil </div>
              <div className="cMF">MXN</div>
            </div>

            <div className="customPlan">
              <div className="descCP">
                Personaliza el plan de pagos a tu medida
              </div>
              <a
                className="customBtn"
                onClick={() => {
                  window.open(
                    "https://wa.me/+5216681466678?text=Hola, Me interesa el proyecto " +
                      project?.title,
                    "_blank"
                  );
                }}
              >
                {/* <WhatsappIcon /> */}
                Invertir Ahora
              </a>
            </div>
          </div>

          <div className="Characteristics">
            <h2>Así puede crecer tu dinero invirtiendo aquí</h2>
            <div className="investProject">
              <div className="numbers1">
                <div className="currentPrice">
                  <span className="txt1">Precio actual:</span>
                  <span className="txt2">
                    $2,906,181
                    <span className="txt3">MXN*</span>
                  </span>
                </div>

                <div className="value">
                  <span className="txt1">
                    Lo que puede valer cuando lo recibas:
                    <span className="txt5">(En 21 meses)</span>
                  </span>
                  <span className="txt2">
                    $3,211,330
                    <span className="txt3">MXN*</span>
                  </span>
                </div>

                <div className="claim">
                  <span className="txt1">Podrías ganar de plusvalía:</span>
                  <span className="txt2">
                    $305,149
                    <span className="txt3">MXN*</span>
                  </span>
                </div>
              </div>
              <div className="rentYear">
                <div className="estYear">
                  <span className="txt1">Tu renta anual estimada:</span>
                  <span className="txt2">
                    $368,928*
                    <span className="txt3">MXN*</span>
                    <span className="txt4">(12.69%)</span>
                  </span>
                </div>
                <div className="label">60% ocupación histórica</div>
              </div>
            </div>
          </div>

          <div className="Characteristics">
            <h2>Características:</h2>
            <div className="subtitle">Entrega en:</div>
            <div className="dateCh">Enero 2026</div>
            <div className="desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Doloribus, eveniet! Aut, deleniti. Saepe voluptatum dignissimos
              assumenda est exercitationem numquam repellendus magni tempora
              deleniti sint quaerat, voluptate consequuntur modi expedita
              delectus temporibus earum quo distinctio consectetur? Aliquam
              repudiandae accusantium consectetur dicta nostrum quibusdam maxime
              quos, ad similique tenetur. Quos labore rerum modi deleniti
              laudantium doloribus, consequuntur repudiandae tenetur suscipit
              vitae sint nulla alias aspernatur? Cumque optio cupiditate ad
              dolorum exercitationem voluptatem quibusdam, itaque hic quidem
              sapiente enim, eaque quam est beatae officia voluptate debitis.
              Accusantium molestias, quos molestiae quibusdam nisi est facilis
              blanditiis animi adipisci totam error eius praesentium commodi
              consectetur!
            </div>
          </div>

          <div className="Characteristics">
            <h2>Documentos:</h2>
            <div className="subtitle">Documentos de interés:</div>
            <div className="docs">
              {project?.docs.map((doc, index) => (
                <div
                  className="doc"
                  onClick={() => {
                    window.open("assets/docs/" + doc.doc, "_blank");
                  }}
                >
                  <DocumentAttachmentIcon />
                  <div className="titleDoc">{doc.title}</div>
                  <div className="titleDesc">{doc.desc}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="Characteristics">
            <h2>Ubicación:</h2>
            <div className="subtitle">Mapa de la ubicación:</div>
            <div id="MapProject"></div>
          </div>

          <div className="protected">
            <img
              width="96"
              height="96"
              src="https://img.icons8.com/stamp/96/shield.png"
              alt="shield"
            />
            <div className="title">
              Durante el proceso de compra con Circulo Inmobiliario verificamos
              el desarrollo por ti.
            </div>
            <ul>
              <li>
                {" "}
                Reputación del desarrollador y su experiencia a través de los
                años en desarrollos realizados.
              </li>
              <li>
                {" "}
                Antecedentes penales y criminales, y procesos legales activos a
                nivel nacional e internacional.
              </li>
              <li> Quejas en PROFECO hacia el desarrollador.</li>
              <li> Uso de suelo destinado al desarrollo.</li>
              <li> Licencia de construcción del desarrollo.</li>
            </ul>
          </div>

          <div className="Characteristics">
            <h2>Otros desarrollos que te podrían interesar:</h2>
            <div className="listProjects">
              {projects.slice(0, 3).map((project, index) => (
                <div className="projectCard" key={index}>
                  <div
                    className="imageProject"
                    onClick={() => {
                      navigate("/project/" + project.id);
                    }}
                  >
                    <div className="imageMark">
                      <img src={"assets/images/" + project.image} alt="" />
                      <div className="messageProject">{project.message}</div>
                    </div>
                  </div>
                  <div className="titleProject">{project.title}</div>
                  <div className="subtitleProject">{project.subtitle}</div>
                  <div className="fromProject">
                    Desde <span className="bold">${project.from}</span> MXN
                  </div>

                  <div className="monthProject">
                    18 Mensualidades de *{" "}
                    <span className="bold">${project.month}</span> MXN
                  </div>

                  <div className="gridPercentages">
                    <div className="grid1">
                      <div className="title">Retorno Total</div>
                      <div className="percentages">17.9% - 23.8%</div>
                    </div>

                    <div className="grid2">
                      <div className="title">Renta Anualizado</div>
                      <div className="percentages">12.1% - 25.8%</div>
                    </div>
                  </div>

                  <div className="gridButtonsAction">
                    <a className="btnAction invest">
                      <WhatsappIcon /> Invertir Ahora
                    </a>
                    <a
                      className="btnAction moreInfo"
                      onClick={() => {
                        navigate("/project/" + project.id);
                      }}
                    >
                      Mas Información
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <div className="FooterProject">
        <div className="boxed">
          {/* <div className="AgentInfo">
            <div className="agentImg"></div>
            Le atiende: <div className="name">Martín Flores</div>
          </div> */}

          <div
            className="actionBtn"
            onClick={() => {
              window.open(
                "https://wa.me/+5216681466678?text=Hola, Me interesa el proyecto " +
                  project?.title,
                "_blank"
              );
            }}
          >
            {/* <WhatsappIcon /> */}
            Invertir Ahora
          </div>
        </div>
      </div>
    </div>
  );
};
